<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img
                    class="img-fluid for-light"
                    src="@/assets/images/PSICOMETRIX-logo.jpg"
                    alt="looginpage"
                    width="170px"
                  />
                  <img
                    class="img-fluid for-dark"
                    src="@/assets/images/logo/logo_dark.png"
                    alt="looginpage"
                  />
                </a>
              </div>
              <div class="login-main login-form-card shadow">
                <form
                  class="theme-form"
                  @submit.prevent="confirmarRegistro(formRegister)"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <h5>{{ lang.login.title }}</h5>
                    </div>
                    <div class="col-md-12">
                      <span class="font-unv">{{ lang.login.subtitle }}</span>
                    </div>
                    <div class="col-md-12 mt-1">
                      <span class="font-unv" 
                        ><strong>¿Te registraste pero aún no pagaste? <router-link class="ml-1" tag="a" to="/buscar-pago"><label class="text-info font-unv" style="cursor: pointer;">Ingresa
                        aquí.</label></router-link> </strong></span>
                    </div>
                  </div>

                  <hr />
                  <div class="form-group mt-3">
                    <label class="col-form-label pt-0 font-unv"
                      >{{ lang.login.inputs.first_name }}
                    </label>
                    <label
                      for=""
                      class="col-form-label pt-0 ml-2 text-danger font-unv"
                      >*</label
                    >
                    <input
                      :class="
                        $v.formRegister.first_name.$error === true
                          ? 'form-control is-invalid font-unv'
                          : 'form-control font-unv'
                      "
                      type="text"
                      required=""
                      :placeholder="lang.login.inputs.first_name_example"
                      v-model.trim="$v.formRegister.first_name.$model"
                    />

                    <div
                      class="invalid-tooltip font-unv mt-2 mb-2"
                      v-if="$v.formRegister.first_name.$error"
                    >
                      {{ lang.login.validations.error_first_name }}
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label pt-0 font-unv">{{
                      lang.login.inputs.last_name
                    }}</label>
                    <label for="" class="col-form-label pt-0 ml-2 text-danger"
                      >*</label
                    >
                    <input
                      :class="
                        $v.formRegister.last_name.$error === true
                          ? 'form-control is-invalid font-unv'
                          : 'form-control font-unv'
                      "
                      type="text"
                      required=""
                      :placeholder="lang.login.inputs.last_name_example"
                      v-model.trim="$v.formRegister.last_name.$model"
                    />

                    <div
                      class="invalid-tooltip font-unv"
                      v-if="$v.formRegister.last_name.$error"
                    >
                      {{ lang.login.validations.error_last_name }}
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label font-unv">{{
                      lang.login.inputs.email
                    }}</label>
                    <label
                      for=""
                      class="col-form-label pt-0 ml-2 text-danger font-unv"
                      >*</label
                    >
                    <input
                      :class="
                        $v.formRegister.email.$error === true
                          ? 'form-control is-invalid font-unv'
                          : 'form-control font-unv'
                      "
                      type="text"
                      required=""
                      :placeholder="lang.login.inputs.email_example"
                      v-model.lazy="$v.formRegister.email.$model"
                    />

                    <small class="form-text text-muted" id="dniHelp1">{{
                      lang.login.inputs.email_text_1
                    }}</small>

                    <div
                      class="invalid-tooltip font-unv"
                      v-if="$v.formRegister.email.$dirty"
                    >
                      <div v-if="!$v.formRegister.email.required">
                        {{ lang.login.validations.error_email }}
                      </div>
                      <div v-if="!$v.formRegister.email.email">
                        {{ lang.login.validations.error_email_format }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label font-unv">{{
                      lang.login.inputs.radio_label
                    }}</label>

                    <div
                      class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml d-flex justify-content-between"
                    >
                      <div class="radio radio-primary">
                        <input
                          id="radioinline2"
                          type="radio"
                          name="radio1"
                          value="1"
                          :checked="true"
                          v-model="formRegister.type_dni"
                        />
                        <label class="mb-0 font-unv" for="radioinline2">{{
                          lang.login.inputs.radio_rut
                        }}</label>
                      </div>
                      <div class="radio radio-primary">
                        <input
                          id="radioinline3"
                          type="radio"
                          name="radio1"
                          value="2"
                          v-model="formRegister.type_dni"
                        />
                        <label class="mb-0 font-unv" for="radioinline3">{{
                          lang.login.inputs.radio_other
                        }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label font-unv">{{
                      lang.login.inputs.dni
                    }}</label>
                    <label
                      for=""
                      class="col-form-label pt-0 ml-2 text-danger font-unv"
                      >*</label
                    >
                    <input
                      :class="
                        $v.formRegister.dni.$error === true ||
                        error_min_rut ||
                        error_max_rut ||
                        error_format_rut ||
                        error_min_pass ||
                        error_format_pass
                          ? 'form-control is-invalid font-unv'
                          : 'form-control font-unv'
                      "
                      type="text"
                      :placeholder="
                        formRegister.type_dni == 1
                          ? lang.login.inputs.dni_example_1
                          : lang.login.inputs.dni_example_2
                      "
                      v-model.trim="$v.formRegister.dni.$model"
                    />

                    <div
                      class="invalid-tooltip font-unv"
                      v-if="$v.formRegister.dni.$dirty"
                    >
                      <div v-if="!$v.formRegister.dni.required">
                        {{ lang.login.validations.error_dni }}
                      </div>
                    </div>
                    <div class="invalid-tooltip font-unv">
                      <div v-if="error_min_rut">
                        {{ lang.login.validations.error_dni_min }}
                      </div>
                    </div>

                    <div class="invalid-tooltip font-unv">
                      <div v-if="error_max_rut">
                        {{ lang.login.validations.error_dni_max }}
                      </div>
                    </div>

                    <div class="invalid-tooltip font-unv">
                      <div v-if="error_format_rut">
                        {{ lang.login.validations.error_dni_format }}
                      </div>
                    </div>

                    <div class="invalid-tooltip">
                      <div v-if="error_min_pass">
                        {{ lang.login.validations.error_dni_other_min }}
                      </div>
                    </div>

                    <div class="invalid-tooltip font-unv">
                      <div v-if="error_format_pass">
                        {{ lang.login.validations.error_dni_other_format }}
                      </div>
                    </div>

                    <small
                      class="form-text text-muted font-unv mt-2"
                      id="dniHelp1"
                      v-if="formRegister.type_dni == 1"
                      >{{ lang.login.inputs.dni_small_text_1 }}</small
                    >

                    <small
                      class="form-text text-muted font-unv mt-2"
                      id="dniHelp2"
                      v-if="formRegister.type_dni == 2"
                      >{{ lang.login.inputs.dni_small_text_2 }}</small
                    >
                  </div>

                  <div class="form-group">
                    <label class="col-form-label font-unv">{{
                      lang.login.inputs.phone_student
                    }}</label>

                    <small for="" class="pt-0 ml-2 text-danger font-unv"
                      >(Opcional)</small
                    >
                    <input
                      class="form-control font-unv"
                      type="text"
                      placeholder="+560000000"
                      v-mask="'+56#########'"
                      v-model.trim="formRegister.phone_student"
                    />
                  </div>

                  <div class="form-group">
                    <label for="" class="col-form-label">{{
                      lang.login.inputs.school
                    }}</label>
                    <label for="" class="col-form-label pt-0 ml-2 text-danger"
                      >*</label
                    >

                    <div class="row" v-show="listSchoolGetter.length == 0">
                      <div class="col-md-12 text-center">
                        <div class="loader-box">
                          <div class="loader-1"></div>
                        </div>
                      </div>
                    </div>

                    <multiselect
                      v-if="listSchoolGetter.length > 0"
                      v-model="$v.formRegister.school.$model"
                      :options="listSchoolGetter"
                      :appendToBody="true"
                      track-by="label"
                      label="label"
                      placeholder="Selecciona una opción..."
                      select-label=""
                      selected-label="Seleccionado"
                      deselectLabel="Remover"
                      openDirection="bottom"
                      class="font-unv"
                      :allow-empty="false"
                    ></multiselect>

                    <div
                      class="invalid-tooltip font-unv"
                      v-if="$v.formRegister.school.$dirty"
                    >
                      <div v-if="!$v.formRegister.school.required">
                        {{ lang.login.validations.error_school }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="card shadow">
                      <div class="media p-20">
                        <div class="radio radio-primary mr-3">
                          <input
                            id="radio14"
                            type="radio"
                            name="test_id"
                            value="1"
                            v-model="formRegister.test_id"
                            :checked="true"
                          />
                          <label for="radio14"></label>
                        </div>
                        <div class="media-body">
                          <h6 class="mt-0 mega-title-badge mt-2 h5">
                            Test Vocacional<span
                              class="badge badge-primary pull-right"
                              >$18.080</span
                            >
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h6 class="text-muted mt-4 or font-unv">Apoderado</h6>

                  <div class="form-group">
                    <label class="col-form-label font-unv">{{
                      lang.login.inputs.representative
                    }}</label>
                    <label for="" class="col-form-label pt-0 ml-2 text-danger"
                      >*</label
                    >
                    <input
                      :class="
                        $v.formRegister.representative.$error === true
                          ? 'form-control is-invalid font-unv'
                          : 'form-control font-unv'
                      "
                      type="text"
                      required=""
                      :placeholder="lang.login.inputs.representative"
                      v-model.lazy="$v.formRegister.representative.$model"
                    />

                    <div
                      class="invalid-tooltip font-unv"
                      v-if="$v.formRegister.representative.$dirty"
                    >
                      <div v-if="!$v.formRegister.representative.required">
                        {{ lang.login.validations.error_representative }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label font-unv">{{
                      lang.login.inputs.phone_representative
                    }}</label>
                    <small for="" class="pt-0 ml-2 text-danger font-unv"
                      >(Opcional)</small
                    >
                    <input
                      class="form-control font-unv"
                      type="text"
                      placeholder="+560000000"
                      v-mask="'+56#########'"
                      v-model.trim="formRegister.phone_representative"
                    />
                  </div>

                  <div class="form-group mb-0">
                    <button
                      class="btn btn-primary btn-block font-unv"
                      type="submit"
                      :disabled="$v.$invalid"
                    >
                      <span
                        v-if="spinner"
                        class="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span v-if="!spinner">{{
                        lang.login.inputs.button_register
                      }}</span>
                      <span v-if="spinner">{{
                        lang.login.inputs.button_register_save
                      }}</span>
                    </button>
                  </div>

                  <p class="mt-4 mb-0 font-unv">
                    {{ lang.login.inputs.text_complete_pay }}

                    <router-link class="ml-2" tag="a" to="/buscar-pago">
                      <strong>{{ lang.login.inputs.link_complete_pay }}</strong>
                    </router-link>
                  </p>
                  <div class="social mt-4 text-right">
                    <small for="" class="pt-0 ml-2 text-danger font-unv"
                      >* Campos requeridos</small
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Register",
  data() {
    return {
      spinner: false,
      formRegister: {
        first_name: "",
        last_name: "",
        email: "",
        dni: "",
        phone_student: "",
        school: "",
        type_dni: 1,
        representative: "",
        phone_representative: "",
        test_id: 1,
      },
      error_min_rut: false,
      error_max_rut: false,
      error_format_rut: false,
      error_min_pass: false,
      error_format_pass: false,
    };
  },
  validations: {
    formRegister: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      dni: {
        required,
      },
      school: {
        required,
      },
      representative: {
        required,
      },
    },
  },
  computed: {
    ...mapState("Lang", ["lang"]),
    ...mapGetters("Catalogs", ["listSchoolGetter"]),
  },
  methods: {
    ...mapActions("Catalogs", ["getListSchool"]),
    ...mapActions("Register", ["register"]),
    validateDni(type) {
      this.error_min_rut = false;
      this.error_max_rut = false;
      this.error_format_rut = false;
      this.error_min_pass = false;
      this.error_format_pass = false;

      if (type == 1) {
        if (this.formRegister.dni.length < 9) {
          this.error_min_rut = true;
          return false;
        } else {
          this.error_min_rut = false;
        }

        if (this.formRegister.dni.length > 10) {
          this.error_max_rut = true;
          return false;
        } else {
          this.error_max_rut = false;
        }

        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(this.formRegister.dni)) {
          this.error_format_rut = true;
          return false;
        } else {
          this.error_format_rut = false;
          return true;
        }
      } else if (type == 2) {
        if (this.formRegister.dni.length < 6) {
          this.error_min_pass = true;
          return false;
        } else {
          this.error_min_pass = false;
        }

        if (!/^([A-Za-z0-9])*$/.test(this.formRegister.dni)) {
          this.error_format_pass = true;
          return false;
        } else {
          this.error_format_pass = false;
          return true;
        }
      }
    },
    confirmarRegistro(form) {
      if (!this.validateDni(form.type_dni)) {
        return;
      }
      this.$swal({
        title: "Guardar información",
        html: "<span class='font-unv'>¿Estás seguro de guardar el registro?</span>",
        showCancelButton: true,
        confirmButtonText: "Si",
        confirmButtonColor: "#51BB25",
        cancelButtonText: "No",
        cancelButtonColor: "#BD2130",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(async (result) => {
        if (result.value) {
          this.registrarCliente(form);
        } else {
        }
      });
    },
    async registrarCliente(form) {
      this.spinner = true;
      try {
        const registro = await this.axios.post(
          "/register/guardar-registro",
          form
        );
        this.spinner = false;

        if (registro.data.status == "email-exist") {
          this.$swal({
            title: this.lang.login.notifications.email_warning_title,
            text: registro.data.msg,
            type: "warning",
          });
        } else if (registro.data.status == "dni-exist") {
          this.$swal({
            title: this.lang.login.notifications.email_warning_title,
            text: registro.data.msg,
            type: "warning",
          });
        } else if (registro.data.status == "done") {
          this.register(registro.data.data);
          this.$swal({
            title: "¡Exito!",
            html: "<span class='font-unv'>Su registro has sido procesado con éxito, ahora debes realizar el pago correspondiente para activar su registro y acceder de nuestros servicios.</span>",
            showCancelButton: false,
            type: "success",
            confirmButtonText: "Ir a pagar",
            confirmButtonColor: "#51BB25",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then(async (result) => {
            if (result.value) {
              this.$router.push(
                `/pagar/${registro.data.data.code_transaction}`
              );
            } else {
            }
          });
        }
      } catch (error) {
        throw error;
      }
    },
    completarPago() {
      this.$swal({
        title: "Ingrese rut del registrado",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Buscar",
        showLoaderOnConfirm: true,
        preConfirm: (dni) => {
          console.log(dni);
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {});
    },
  },
  created() {
    this.getListSchool();
  },
};
</script>
